if ($('main').hasClass('ourwork-page')) {
    $('[data-fancybox="ourworks_images"]').fancybox({
        buttons: [
            'zoom',
            'close'
        ],
    });
    $(window).on('load resize', function(){
        if ($(window).width() >= 1352){
            let items_number = $('.ourworks__item').length;

    
            if(items_number >= 6){
                $('.ourworks__item:nth-child(6n-5)').addClass('type6_1');
                $('.ourworks__item:nth-child(6n-4)').addClass('type6_2');
                $('.ourworks__item:nth-child(6n-3)').addClass('type6_3');
                $('.ourworks__item:nth-child(6n-2)').addClass('type6_4');
                $('.ourworks__item:nth-child(6n-1)').addClass('type6_5');
                $('.ourworks__item:nth-child(6n)').addClass('type6_6');
                //КЛОНИРУЕМ КАРТИНКИ ЧТО БЫ НЕ БЫЛО ПУСТЫХ МЕСТ
                let round = Math.ceil(items_number / 6);
                let shortage = 6*round - items_number;
                for(i=1; i<=shortage; i++) {
                    $('.ourworks__item:nth-child('+i+')').clone().appendTo('.ourworks .swiper-wrapper').removeClass().addClass('ourworks__item').addClass('clone').addClass('type6_'+(6 - shortage + i)+'');
                }   
            }
        }


    });


    //SWIPER
    $(window).on('load', function () {
        setTimeout(function () {
            let mySwiperMain = new Swiper('.ourworks', {
                init: true,
                width: 350,
                speed: 500,
                loop: false,
                slidesPerView: 'auto',
                initialSlide: 0,
                direction: "horizontal",
                allowTouchMove: true,
                breakpoints: {
                    825: {
                        allowTouchMove: false
                    }
                }

            });
        }, 500)
 
    });
}


