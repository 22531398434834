$(function() {




    // $('input[type="tel"]').mask("+7(999) 999-99-99");


    // var radioValue = number_format($(this).val(), 0, ',', ' ');


    function number_format(number, decimals, dec_point, thousands_sep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec);
                return '' + (Math.round(n * k) / k)
                    .toFixed(prec);
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
            .split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '')
            .length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1)
                .join('0');
        }
        return s.join(dec);
    }




    //SEND FORMS 
    var dwl_price_adress = '/download';
    var dwl_price_name = '';
    $('.dwl_price').on('click', function(){
        dwl_price_adress = $(this).attr('data-download');
        dwl_price_name = $(this).attr('data-name');        
    });

    $("#phone input").inputmask("+7(999)999-99-99");
    $("#phone2 input").inputmask("+7(999)999-99-99");
    $("#phone3 input").inputmask("+7(999)999-99-99");
    $('.section4__form, .section1__form, .sign_up_form').on('submit', function (e) {

        e.preventDefault();
        var $this = $(this);
        //SECTION4 FORM
        if ($this.hasClass('section4__form')) {
            //- Валидация ------------------------------
            var name = $('#name input').val();
            var phone = $('#phone input').val();
            var token_var = $this.find('[name="_token"]').val();
            var patternPhone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/;
            var successPhone = false;

            //- Проверка на пустое поле
            if (name.length !== 0) {
                $('#name .input-block__message').text('');
            } else {
                $('#name .input-block__message').text('Введите имя');
            };

            //- Проверка на пустое поле
            if (phone.length !== 0) {
                $('#phone .input-block__message').text('');
                if (phone.search(patternPhone) == 0) {
                    $('#phone .input-block__message').text('');
                    successPhone = true;
                } else {
                    $('#phone .input-block__message').text('Введите корректный номер телефона');
                }
            } else {
                $('#phone .input-block__message').text('Введите номер телефона');
            }

            //- Проверка на соответсвие всех полей необходимым проверкам 
            if (name.length !== 0 && successPhone == true) {
                $this[0].reset();

                $.ajax({
                    url: $this.attr('action'), // Файл в который отправить данные 
                    type: 'POST', // Метод отправки данных
                    data: {
                        _token: token_var,
                        sender_name: name,
                        sender_phone: phone
                    }, // Получить все данные формы  https://ruseller.com/jquery?id=8
                    success: function (data) { // Функция выполняемая в случае успешной отправки формы
                        response = $.parseJSON(data);
                        console.log(response.status);
                        console.log(response.message);
                        $('#thank_claim_link').click();

                    },
                    error: function (jqXHR, exception) {
                        response = $.parseJSON(jqXHR.responseText);
                        var name_error_serv = response.errors.sender_name[0];
                        var tel_error_serv = response.errors.sender_phone[0];
                        if (name_error_serv) {
                            $('#name .input-block__message').text(name_error_serv);
                        };
                        if (tel_error_serv) {
                            $('#phone .input-block__message').text(tel_error_serv);
                        };
                    }
                });
            };
        }


        //CALLBACK FORM********
        if ($this.hasClass('section1__form')) {

            //- Валидация ------------------------------
            var name = $('#name2 input').val();
            var phone = $('#phone2 input').val();
            var token_var = $this.find('[name="_token"]').val();
            var patternPhone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/;
            var successPhone = false;

            //- Проверка на пустое поле
            if (name.length !== 0) {
                $('#name2 .input-block__message').text('');
            } else {
                $('#name2 .input-block__message').text('Введите имя');
            };

            //- Проверка на пустое поле
            if (phone.length !== 0) {
                $('#phone2 .input-block__message').text('');
                if (phone.search(patternPhone) == 0) {
                    $('#phone2 .input-block__message').text('');
                    successPhone = true;
                } else {
                    $('#phone2 .input-block__message').text('Введите корректный номер телефона');
                }
            } else {
                $('#phone2 .input-block__message').text('Введите номер телефона');
            }

            //- Проверка на соответсвие всех полей необходимым проверкам 
            if (name.length !== 0 && successPhone == true) {
                $this[0].reset();

                $.ajax({
                    url: $this.attr('action'), // Файл в который отправить данные 
                    type: 'POST', // Метод отправки данных
                    data: {
                        _token: token_var,
                        sender_name: name,
                        sender_phone: phone
                    }, // Получить все данные формы  https://ruseller.com/jquery?id=8
                    success: function (data) { // Функция выполняемая в случае успешной отправки формы
                        response = $.parseJSON(data);
                        console.log(response.status);
                        console.log(response.message);
                        $.fancybox.close();
                        $('#thank_claim_link').click();

                    },
                    error: function (jqXHR, exception) {
                        response = $.parseJSON(jqXHR.responseText);
                        var name_error_serv = response.errors.sender_name[0];
                        var tel_error_serv = response.errors.sender_phone[0];
                        if (name_error_serv) {
                            $('#name2 .input-block__message').text(name_error_serv);
                        };
                        if (tel_error_serv) {
                            $('#phone2 .input-block__message').text(tel_error_serv);
                        };
                    }
                });
            };

        }

        //SIGN UP FORM ****************
        if ($this.hasClass('sign_up_form')) {

            //- Валидация ------------------------------
            var downloading = 'Скачивание прайса';
            var name = $('#name3 input').val();
            var email = $('#email3 input').val();
            var phone = $('#phone3 input').val();
            var token_var = $this.find('[name="_token"]').val();
            var patternEmail = /^[\.a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
            var successEmail = false;

            //- Проверка на пустое поле
            if (name.length !== 0) {
                $('#name3 .input-block__message').text('');
            } else {
                $('#name3 .input-block__message').text('Введите имя');
            };

            //- Проверка на пустое поле
            if (phone.length !== 0) {
                $('#phone3 .input-block__message').text('');
                if (phone.search(patternPhone) == 0) {
                    $('#phone3 .input-block__message').text('');
                    successPhone = true;
                } else {
                    $('#phone3 .input-block__message').text('Введите корректный номер телефона');
                }
            } else {
                $('#phone3 .input-block__message').text('Введите номер телефона');
            }
            //- Проверка на пустое поле
            if (email.length !== 0) {
                $('#email3 .input-block__message').text('');
                if (email.search(patternEmail) == 0) {
                    $('#email3 .input-block__message').text('');
                    successEmail = true;
                } else {
                    $('#email3 .input-block__message').text('Введите корректный email');
                }
            } else {
                $('#email3 .input-block__message').text('Введите ваш email');
            }
            //- Проверка на соответсвие всех полей необходимым проверкам 
            if (name.length !== 0 && successPhone == true && successEmail == true) {
                $this[0].reset();

                $.ajax({
                    url: $this.attr('action'), // Файл в который отправить данные 
                    type: 'POST', // Метод отправки данных
                    data: {
                        _token: token_var,
                        downloading: downloading + ' ' + dwl_price_name,
                        sender_name: name,
                        sender_email: email,
                        sender_phone: phone
                    }, // Получить все данные формы  https://ruseller.com/jquery?id=8
                    success: function (data) { // Функция выполняемая в случае успешной отправки формы
                        response = $.parseJSON(data);
                        console.log(response.status);
                        console.log(response.message);
                        $.fancybox.close();

                        var link = document.createElement('a');
                        link.setAttribute('href', dwl_price_adress);
                        link.setAttribute('download', 'price');
                        link.click();

                        console.log('test download');
                        console.log('dynamic path dowload' + dwl_price_adress)
                        console.log('dynamic name dowload' + downloading+' '+ dwl_price_name)
                    },
                    error: function (jqXHR, exception) {
                        response = $.parseJSON(jqXHR.responseText);
                        var name_error_serv = response.errors.sender_name[0];
                        var tel_error_serv = response.errors.sender_phone[0];
                        if (name_error_serv) {
                            $('#name3 .input-block__message').text(name_error_serv);
                        };
                        if (tel_error_serv) {
                            $('#phone3 .input-block__message').text(tel_error_serv);
                        };
                        $('#email3 .input-block__message').text(response.errors.email);
                        console.log(response.errors);
                    }
                });
            };

        }



    });








});

