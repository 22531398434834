$('.footer__block-form').on('submit', function (e) {
    e.preventDefault();
    var $this = $(this);

    //- Валидация ------------------------------
    var token_var = $this.find('[name="_token"]').val();
    var email = $('#email input').val();
    var chack_box_var = $this.find('[name="accepted"]').val();
    var chack_box_chached = $('#check').is(':checked');
    var patternEmail = /^[\.a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
    var successEmail = false;

    //- Проверка на пустое поле
    if (email.length !== 0) {
        $('#email .input-block__message').text('');
        if (email.search(patternEmail) == 0) {
            $('#email .input-block__message').text('');
            successEmail = true;
        } else {
            $('#email .input-block__message').text('Введите корректный email');
        }
    } else {
        $('#email .input-block__message').text('Введите ваш email');
    }
    
    if (chack_box_chached == true){
        $('.checkbox-custom').removeClass('empty_check');
    }else{
        $('.checkbox-custom').addClass('empty_check');
        
    }

    //- Проверка на соответсвие всех полей необходимым проверкам 
    if (successEmail == true && chack_box_chached == true) {
        $this[0].reset();

        $.ajax({
            url: $this.attr('action'), // Файл в который отправить данные 
            type: 'POST', // Метод отправки данных
            data: {
                _token: token_var,
                email: email,
                accepted: chack_box_var
            }, // Получить все данные формы  https://ruseller.com/jquery?id=8
            success: function (data) { // Функция выполняемая в случае успешной отправки формы
                response = $.parseJSON(data);
                if (response.message == 'You subscribe successfully'){
                    $('#thank_mail_link').click();
                } else if (response.message == 'This email already taken') {
                    $('#email .input-block__message').text('email уже существует');
                }
                console.log('success');
                  
            },
            error: function (jqXHR, exception){
                response = $.parseJSON(jqXHR.responseText);
                $('#email .input-block__message').text(response.errors.email);
            } 
        });
    }
});
$('.checkbox-custom').on('click', function(){
    $('.checkbox-custom').removeClass('empty_check');
})
$(".modalbox").fancybox();
