if ($('main').hasClass('galery-page')) {
    var slideTime = 3000;
    var swiper = new Swiper('.swiper-container', {
        slidesPerView: 3,
        slidesPerGroup: 3,
        centeredSlides: false,
        loop: false,
        initialSlide: 1,
        breakpoints: {
            150: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                initialSlide: 0,
            },
            650: {
                slidesPerView: 2,
                slidesPerGroup: 1,
                initialSlide: 0,
            },
            1100: {
                slidesPerView: 3,
                initialSlide: 0,
            }
        },
        autoplay: {
            delay: slideTime,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pag',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '"><svg id="svg" width="18" height="18" viewport="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><circle r="5" cx="9" cy="9" fill="black" stroke-dasharray="40" stroke-dashoffset="0"></circle><circle id="bar" r="8" cx="9" cy="9" fill="transparent" stroke-dasharray="50" stroke-dashoffset="0"></circle></svg></span>';
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    $(window).on('load', function () {
        $('.swiper-pagination-bullet #svg #bar').css('stroke-dashoffset', 50);
        $('.swiper-pagination-bullet-active #svg #bar').css('stroke-dashoffset', 0);
    });
    swiper.on('init', function () {
        $('.swiper-pagination-bullet #svg #bar').css('stroke-dashoffset', 50);
        $('.swiper-pagination-bullet-active #svg #bar').css('stroke-dashoffset', 0);
    });
    swiper.on('slideChangeTransitionEnd', function () {
        $('.swiper-pagination-bullet #svg #bar').css('stroke-dashoffset', 50);
        $('.swiper-pagination-bullet-active #svg #bar').css('stroke-dashoffset', 0);
    })

    $('.section1__tabs-buttons-el').on('click', function () {
        $('.section1__tabs-buttons-el').removeClass('active');
        $(this).addClass('active');
        $('.section1__tabs-content-el').removeClass('active');
        $('.section1__tabs-content-el[data-tab=' + $(this).attr('data-tab') + ']').addClass('active');
    })
}


