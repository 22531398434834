$(function() {
   $(document).on("mouseenter ", ".catalog__item-nav-btn", function(e) {
      e.preventDefault();
      let item = $(this).closest(".catalog__item");
      let numPhoto = $(this).data("photo");
      let figure = item.find(".catalog__item-gallery").find('[data-photo="' + numPhoto + '"]');
      item.find("[data-photo]").removeClass("cover_active");
      $(figure).addClass("cover_active")
  });
});

